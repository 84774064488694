import axios from 'axios';

export const modalHandler = (): void => {
    const openLinks: NodeListOf<HTMLElement> = document.querySelectorAll(".js-open-modal__link");
    const closeLinks: NodeListOf<HTMLElement> = document.querySelectorAll(".js-close-modal__link");
    
    for (let i: number = 0; i < openLinks.length; i++) {
        openLinks[i].addEventListener("click", function (e) {
            e.preventDefault();

            let modal: HTMLElement | null = toggleModal(this, false);

            if (modal !== null) {
                const url: string | null | undefined = this.getAttribute('data-url');
                if (url !== null && url !== undefined && url !== "") {
                    hentDataTilModalVindu(url, modal);
                }
            }
        });
    }

    for (let i: number = 0; i < openLinks.length; i++) {
        closeLinks[i].addEventListener("click", function (e) {
            e.preventDefault();
            toggleModal(this, true);
        });
    }

    function toggleModal(clickedItem: HTMLElement, hideModal: boolean): HTMLElement | null {
        const modalId: string | null = clickedItem.getAttribute("data-modal-id");
        if (modalId !== null) {
            let modal: HTMLElement | null = document.getElementById(modalId.toString());
            if (modal != null) {
                if (hideModal) {
                    modal.classList.remove("c-modal-visible");
                } else {
                    modal.classList.add("c-modal-visible");
                    modal.focus();
                }
            }
            return modal;
        }
        return null;
    }

    function hentDataTilModalVindu(url: string, modalEl: HTMLElement): void {
        const titleEl: HTMLElement | null = modalEl.querySelector(".js-c-modal__tittel"); 
        const ingressEl: HTMLElement | null = modalEl.querySelector(".js-c-modal__ingress"); 
        const bodyEl: HTMLElement | null = modalEl.querySelector(".js-c-modal__body"); 

        showLoadingIcon(modalEl);
        
        axios.request<ServerData>({
            url: url
        }).then(({ data }) => {

            if (titleEl !== null)
                titleEl.innerHTML = "<h1>" + data.tittel + "</h1>";  

            if (ingressEl !== null)
                ingressEl.innerHTML = data.ingress;

            if (bodyEl !== null)
                bodyEl.innerHTML = data.tekst.value;

            hideLoadingIcon(modalEl);
        })
    }

    function showLoadingIcon(modalEl: HTMLElement): void {
        const loadingContainer: HTMLElement | null = modalEl.querySelector(".js-c-modal-loader");

        if (loadingContainer !== null)
            loadingContainer.style.display = "block";
    }

    function hideLoadingIcon(modalEl: HTMLElement): void {
        const loadingContainer: HTMLElement | null = modalEl.querySelector(".js-c-modal-loader");

        if (loadingContainer !== null)
            loadingContainer.style.display = "none";
    }
}

interface ServerData {
    tittel: string;
    tekst: { value: string };
    ingress: string;
}
