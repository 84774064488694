import SiteImproveHandler, { SiteImproveEvents, SiteImproveKategorier } from './SiteImproveEventsHandler';

export const handleExpandCollapse = (): void => {
    const expandElements = document.getElementsByClassName('js-expand');
    for (const clickableElement of Array.from(expandElements)) {
        const expandAreaId = clickableElement.attributes['data-expand'].value;
        const expandArea = document.getElementById(expandAreaId);
        if (expandArea != null) {
            if (!expandArea.classList.contains('sr-only')) {
                expandArea.classList.add('sr-only');
            }

            const onClickHandler = (e: any): void => {
                e.preventDefault();
                
                const isOpen = !expandArea.classList.contains('sr-only');
                const buttonExpandedClass = clickableElement.attributes['data-expanded-class'].value;
                const expandAreaExpandedClass = expandArea.attributes['data-expanded-class'].value;
                const openParent = clickableElement.parentElement?.getAttribute('data-expanded-class');

                if (isOpen) {

                    if (typeof expandArea.animate !== "undefined") {
                        expandArea.style.overflow = "hidden";
                        let height: number = expandArea.clientHeight;
                        expandArea.animate([
                            // keyframes
                            { height: height+'px' },
                            { height: '0' }
                        ], {
                            // timing options
                            duration: 200,
                            iterations: 1
                        }).finished.then(function () {
                            expandArea.classList.add('sr-only');
                            clickableElement.classList.remove(buttonExpandedClass);
                            expandArea.classList.remove(expandAreaExpandedClass);
                            clickableElement.setAttribute("aria-expanded", "false");

                            expandArea.style.height = '';
                            expandArea.style.overflow = '';
                            expandArea.style.display = '';

                            if (typeof clickableElement.scrollIntoViewIfNeeded !== undefined) { 
                                clickableElement.scrollIntoViewIfNeeded?.(false);
                            } else {
                                clickableElement.scrollIntoView();
                            }

                            if (openParent !== null) {
                                clickableElement.parentElement?.classList.remove(openParent as string);
                            }

                            
                        });
                    } else {
                        expandArea.classList.add('sr-only');
                        clickableElement.classList.remove(buttonExpandedClass);
                        expandArea.classList.remove(expandAreaExpandedClass);
                        clickableElement.setAttribute("aria-expanded", "false");
                    }
                    
                } else {
                    SiteImproveHandler.registerSiteImproveEvent(SiteImproveKategorier.Lamell, SiteImproveEvents.Klikk, hentLabel(clickableElement))

                    if (typeof expandArea.animate !== "undefined") {
                        expandArea.style.overflow = "hidden";
                        expandArea.style.display = "block";
                        expandArea.style.minHeight = "0";
                        expandArea.classList.remove('sr-only');
                        expandArea.classList.add(expandAreaExpandedClass);
                        let height: number = expandArea.clientHeight;
                        expandArea.animate([
                            // keyframes
                            { height: '0' },
                            { height: height+'px' }
                        ], {
                            // timing options
                            duration: 200,
                            iterations: 1
                        }).finished.then(function () {
                            clickableElement.classList.add(buttonExpandedClass);
                            clickableElement.setAttribute("aria-expanded", "true");
                            expandArea.style.overflow = '';
                            expandArea.style.minHeight = '';
                            if (openParent !== null) {
                                clickableElement.parentElement?.classList.add(openParent as string)
                            }
                        });
                    } else {
                        SiteImproveHandler.registerSiteImproveEvent(SiteImproveKategorier.Lamell, SiteImproveEvents.Klikk, hentLabel(clickableElement))
                        expandArea.classList.remove('sr-only');
                        clickableElement.classList.add(buttonExpandedClass);
                        expandArea.classList.add(expandAreaExpandedClass);
                        clickableElement.setAttribute("aria-expanded", "true");

                        if (openParent !== null) {
                            clickableElement.parentElement?.classList.add(openParent as string)
                        }
                    }
                    expandArea.focus()
                }
            };

            clickableElement.addEventListener('click', onClickHandler);
        }
    }
};

const hentLabel = (clickEl: Element): string => {
    
    if(clickEl == null || clickEl == undefined)
        return document.title;

    const htmlEl = clickEl as HTMLElement;

    return htmlEl.innerText.replace(/\r?\n|\r/g, " ");
}
