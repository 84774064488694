import React from 'react';
import ReactDOM from 'react-dom';

// Polyfills
import 'vite/modulepreload-polyfill';
import 'core-js/stable';

import { handleExpandCollapse } from './Scripts/Utilities/expand';
import { observeSlideInElements } from './Scripts/Utilities/slideInOnScroll';
import { setupMenuBehavoir } from './Scripts/Utilities/menuBehaviour';
import { setupTabMonitor } from './Scripts/Utilities/tabMonitor';
import { modalHandler } from './Scripts/Utilities/Modal';
import { filterNyhetsarkiv } from './Scripts/Utilities/FilterNyhetsarkiv';

setupTabMonitor();
setupMenuBehavoir();
handleExpandCollapse();
observeSlideInElements();

modalHandler();
filterNyhetsarkiv.init();

//#region React Axe setup
// @ts-ignore import.meta.env er satt av vitejs
// https://vitejs.dev/guide/env-and-mode.html
if (import.meta.env.DEV) {
    import('@axe-core/react')
        .then(({ default: axe }) => axe(React, ReactDOM, 1000))
        .then(() => console.info('Loaded @axe-core/react plugin'));
}
//#endregion
