
export const filterNyhetsarkiv: any = (function () {

    let nyhetsElementer = document.querySelectorAll(".js-nyhetsarkiv-container .js-nyhetsarkiv-klikkbar");
    let checkboxes: NodeListOf<Element> = document.querySelectorAll(".js-nyhetsarkiv__filter-checkbox");
    

    function bindCheckboxes(): void {
        
        for(let i = 0;i<checkboxes.length;i++) {
            checkboxes[i].addEventListener("change", function (e: Event) {
                
                toggleNyheter();
            });
        }
    }

    function hentAlleAktiveTagIDer(): string[] {
        let IDer = [];
        for(let i = 0; i<checkboxes.length;i++) {
            if((checkboxes[i] as HTMLInputElement).checked) {
                IDer.push((checkboxes[i] as HTMLInputElement).value);
            }
        }
        return IDer;
    }

    function toggleNyheter(): void {

        if(nyhetsElementer === null || nyhetsElementer === undefined)
            return;

        let tags: string[] = hentAlleAktiveTagIDer();

        for(let i = 0;i<nyhetsElementer.length;i++) {
            let dataTag = nyhetsElementer[i].getAttribute("data-tag");

            if(dataTag === null || dataTag === undefined || dataTag === "") {
                (nyhetsElementer[i] as HTMLElement).style.display = "none";
                continue;
            }

            let nyhetTags: string[] = dataTag.split(",");
            
            let visNyhet: boolean = false;

            
            for(let x = 0;x<nyhetTags.length;x++) {
                if(tags.includes(nyhetTags[x])) {
                    visNyhet = true;
                }
            }

            if(visNyhet) {
                (nyhetsElementer[i] as HTMLElement).style.display = "block";
            } else {
                (nyhetsElementer[i] as HTMLElement).style.display = "none";
            }
        }
    }

    return {
        init: function () {
            if(checkboxes != null && checkboxes.length > 0) {
                bindCheckboxes();  
            }
        }
    };
})();