import { brukerInnlogget } from './LoginBehaviour';
import { startSjekkForInaktivitet } from '@modulis/inaktivitet';
import SiteImproveHandler, { SiteImproveEvents, SiteImproveKategorier } from '../Utilities/SiteImproveEventsHandler';

const menuButton = document.getElementById('nav-menu-button');
const menuCloseButton = document.getElementById('menu-close-button');
const expandMenu = document.getElementById('expand-nav');
const loginButton = document.getElementById('login-button');
const logoutButton = document.getElementById('logout-button');
const dineSiderButton = document.getElementById('dinesider-button');
const langButton = document.getElementById('lang-button');
const searchButton = document.getElementById('search-button');
const searchForm = document.getElementById('header-search-form-container');
const searchFormInput = searchForm?.getElementsByClassName('header-search--input')[0] as HTMLInputElement | null;
const sprakNedtrekk = document.getElementById('sprakvelger-container');
const allTabs = document.getElementsByClassName('menu__navigation-header');
const allLinkAreas = document.getElementsByClassName('menu__navigation-item-list');
const allLinkAreaItems = document.getElementsByClassName('menu__navigation-item-list-item');
const list = document.getElementById('menu-navlist');
const ark = document.getElementById('ark');

let highestListItemDesktop = 0;
let resizingThrottled = false;
let menuOpenCloseThrottled = false;

let menuIsOpen = false;
let searchboxIsOpen = false;
let sprakNedtrekkIsOpen = false;

const allElementsHidden = function (elements: any) {
    let allHidden = true;
    Array.from(elements).forEach((e: any) => {
        if (!e.classList.contains('menu__navigation--hide')) {
            allHidden = false;
        }
    });
    return allHidden;
};

const isDesktop = function () {
    return document.body.clientWidth >= 768;
};

function showSignIn() {
    loginButton?.classList.remove('menu__navigation--hide');
    logoutButton?.classList.add('menu__navigation--hide');
    dineSiderButton?.classList.add('menu__navigation--hide');
}

function hideSignIn() {
    logoutButton?.classList.remove('menu__navigation--hide');
    dineSiderButton?.classList.remove('menu__navigation--hide');
    loginButton?.classList.add('menu__navigation--hide');
}

function sjekkOmBrukerErInnlogget() {
    brukerInnlogget()
        .then((isAuthenticated) => {
            if (isAuthenticated) {
                startSjekkForInaktivitet();
                hideSignIn();
            } else {
                showSignIn();
            }
        })
        .catch(showSignIn);
}

const adjustToResizing = function () {
    if (!resizingThrottled) {
        if (isDesktop()) {
            if (allElementsHidden(allLinkAreas)) {
                if (allTabs != null && allTabs.length > 0) {
                    allTabs[0].classList.add('menu__navigation-header--active');
                    allTabs[0].setAttribute('aria-expanded', 'true');

                    if (allTabs[0].parentElement != null) {
                        allTabs[0].parentElement.classList.add('menu__navigation-item--active');
                    }
                }

                if (allLinkAreas != null && allLinkAreas.length)
                    allLinkAreas[0].classList.remove('menu__navigation--hide');
            }

            sjekkOmBrukerErInnlogget();

            langButton?.classList.remove('menu__navigation--hide');

            if (menuIsOpen) {
                searchForm?.classList.add('menu__navigation--hide');
                searchboxIsOpen = false;

                langButton?.classList.remove('header__action-dropdown-content--expanded');
                sprakNedtrekk?.classList.add('menu__navigation--hide');
                sprakNedtrekkIsOpen = false;
            }
        } else {
            if (menuIsOpen) {
                searchForm?.classList.remove('menu__navigation--hide');
                searchboxIsOpen = true;
            } else {
                loginButton?.classList.add('menu__navigation--hide');
                logoutButton?.classList.add('menu__navigation--hide');
                dineSiderButton?.classList.add('menu__navigation--hide');
                langButton?.classList.add('menu__navigation--hide');
                searchForm?.classList.add('menu__navigation--hide');
                sprakNedtrekk?.classList.add('menu__navigation--hide');
                searchboxIsOpen = false;
            }
        }

        if (list != null) {
            list.style.height = calculateListHeight() + 'px';
        }

        resizingThrottled = true;
        setTimeout(() => {
            resizingThrottled = false;
        }, 200);
    }
};

const getHighestItem = function () {
    let highest = 0;
    for (let j = 0; j < allLinkAreaItems.length; j++) {
        if (allLinkAreaItems[j].clientHeight > highest) {
            highest = allLinkAreaItems[j].clientHeight;
        }
    }

    return highest;
};

const calculateListHeight = function (tabLinkClicked?: Element) {
    let activeListItem = 0;
    let id = '';
    let addItemHeight = false;

    for (let i = 0; i < allLinkAreas.length; i++) {
        if (tabLinkClicked != null) {
            id = tabLinkClicked.attributes['data-menu-toggle'].value;
            if (tabLinkClicked.classList.contains('menu__navigation-header--active')) {
                addItemHeight = true;
            }
        } else {
            const activeTab = document.getElementsByClassName('menu__navigation-header--active')[0];
            if (activeTab != null) {
                id = activeTab.attributes['data-menu-toggle'].value;
                addItemHeight = true;
            }
        }

        if (allLinkAreas[i].id == id) {
            if (addItemHeight) {
                activeListItem = allLinkAreas[i].clientHeight;
            }
        }
    }

    if (isDesktop()) {
        if (getHighestItem() > highestListItemDesktop) {
            highestListItemDesktop = getHighestItem();
        }

        return highestListItemDesktop + allTabs[0].clientHeight;
    } else {
        let tabHeights = 0;
        for (let i = 0; i < allTabs.length; i++) {
            tabHeights = tabHeights + allTabs[i].clientHeight;
        }

        return tabHeights + activeListItem;
    }
};

const openCloseMenu = function () {
    if (menuButton != null && expandMenu != null && ark != null) {
        if (!menuOpenCloseThrottled) {
            if (menuIsOpen) {
                // closing
                menuButton.classList.remove('header__action--menu-close');
                menuButton.setAttribute('aria-expanded', 'false');
                expandMenu.classList.add('menu__navigation--hide');
                ark.classList.add('header__ark-gjemt');

                if (!isDesktop()) {
                    loginButton?.classList.add('menu__navigation--hide');
                    logoutButton?.classList.add('menu__navigation--hide');
                    dineSiderButton?.classList.add('menu__navigation--hide');

                    langButton?.classList.add('menu__navigation--hide');
                    langButton?.classList.remove('header__action-dropdown-content--expanded');
                    langButton?.setAttribute('aria-expanded', 'false');
                    searchForm?.classList.add('menu__navigation--hide');
                    sprakNedtrekk?.classList.add('menu__navigation--hide');
                    sprakNedtrekkIsOpen = false;
                }

                menuIsOpen = false;
            } else {
                // opening
                SiteImproveHandler.registerSiteImproveEvent(
                    SiteImproveKategorier.Innhold,
                    SiteImproveEvents.Klikk,
                    'Hovedmeny',
                );

                searchButton?.setAttribute('aria-expanded', 'false');
                searchForm?.classList.add('menu__navigation--hide');
                searchboxIsOpen = false;

                langButton?.classList.remove('header__action-dropdown-content--expanded');
                sprakNedtrekk?.classList.add('menu__navigation--hide');
                sprakNedtrekkIsOpen = false;

                menuButton.classList.add('header__action--menu-close');
                menuButton.setAttribute('aria-expanded', 'true');
                expandMenu.classList.remove('menu__navigation--hide');
                if (isDesktop()) {
                    langButton?.setAttribute('aria-expanded', 'false');
                    if (list != null) {
                        list.style.height = calculateListHeight() + 'px';
                    }
                }

                ark.classList.remove('header__ark-gjemt');

                if (!isDesktop()) {
                    // loginButton.classList.add('menu__navigation--hide');
                    //  logoutButton.classList.add('menu__navigation--hide');

                    sjekkOmBrukerErInnlogget();

                    langButton.classList.remove('menu__navigation--hide');
                    searchForm.classList.remove('menu__navigation--hide');
                }

                menuIsOpen = true;
            }

            menuOpenCloseThrottled = true;
            setTimeout(() => {
                menuOpenCloseThrottled = false;
            }, 300);
        }
    }
};

const openCloseSearchbox = function () {
    if (!menuOpenCloseThrottled) {
        if (searchboxIsOpen) {
            searchButton?.setAttribute('aria-expanded', 'false');
            searchForm?.classList.add('menu__navigation--hide');
            searchboxIsOpen = false;
        } else {
            searchButton?.setAttribute('aria-expanded', 'true');
            searchForm?.classList.remove('menu__navigation--hide');
            searchboxIsOpen = true;
            searchFormInput?.focus();

            langButton?.classList.remove('header__action-dropdown-content--expanded');
            langButton?.setAttribute('aria-expanded', 'false');
            sprakNedtrekk?.classList.add('menu__navigation--hide');
            sprakNedtrekkIsOpen = false;

            menuButton?.classList.remove('header__action--menu-close');
            menuButton?.setAttribute('aria-expanded', 'false');
            expandMenu?.classList.add('menu__navigation--hide');
            ark?.classList.add('header__ark-gjemt');
            menuIsOpen = false;
        }

        menuOpenCloseThrottled = true;
        setTimeout(() => {
            menuOpenCloseThrottled = false;
        }, 300);
    }
};

const openCloseSprakNedtrekk = function () {
    if (!menuOpenCloseThrottled) {
        if (sprakNedtrekkIsOpen) {
            langButton?.classList.remove('header__action-dropdown-content--expanded');
            langButton?.setAttribute('aria-expanded', 'false');
            sprakNedtrekk?.classList.add('menu__navigation--hide');
            sprakNedtrekkIsOpen = false;
        } else {
            langButton?.classList.add('header__action-dropdown-content--expanded');
            langButton?.setAttribute('aria-expanded', 'true');
            sprakNedtrekk?.classList.remove('menu__navigation--hide');
            sprakNedtrekkIsOpen = true;

            if (isDesktop()) {
                searchButton?.setAttribute('aria-expanded', 'false');
                searchForm?.classList.add('menu__navigation--hide');
                searchboxIsOpen = false;

                menuButton?.classList.remove('header__action--menu-close');
                menuButton?.setAttribute('aria-expanded', 'false');
                expandMenu?.classList.add('menu__navigation--hide');
                ark?.classList.add('header__ark-gjemt');
                menuIsOpen = false;
            }
        }

        menuOpenCloseThrottled = true;
        setTimeout(() => {
            menuOpenCloseThrottled = false;
        }, 300);
    }
};

export const setupMenuBehavoir = (): void => {
    if (menuCloseButton != null) {
        menuCloseButton.addEventListener('click', () => {
            openCloseMenu();
        });
    }

    if (menuButton != null && expandMenu != null) {
        menuOpenCloseThrottled = false;
        menuButton.addEventListener('click', () => {
            openCloseMenu();
        });
    }

    if (searchButton != null) {
        searchButton.addEventListener('click', () => {
            openCloseSearchbox();
        });
    }

    if (langButton != null) {
        langButton.addEventListener('click', () => {
            openCloseSprakNedtrekk();
        });
    }

    Array.from(allLinkAreas).forEach((elem) => elem.classList.add('menu__navigation--hide'));
    Array.from(allTabs).forEach((tabLink: Element) => {
        tabLink.addEventListener('click', () => {
            const id = tabLink.attributes['data-menu-toggle'].value;
            const currentLinkArea = document.getElementById(id);

            if (currentLinkArea != null) {
                if (currentLinkArea.classList.contains('menu__navigation--hide')) {
                    Array.from(allLinkAreas).forEach((elem) => elem.classList.add('menu__navigation--hide'));
                    Array.from(allTabs).forEach((elem) => {
                        elem.classList.remove('menu__navigation-header--active');
                        elem.setAttribute('aria-expanded', 'false');
                    });
                    currentLinkArea.classList.remove('menu__navigation--hide');
                    tabLink.classList.add('menu__navigation-header--active');
                    tabLink.setAttribute('aria-expanded', 'true');

                    Array.from(allTabs).forEach((elem) =>
                        elem.parentElement?.classList.remove('menu__navigation-item--active'),
                    );
                    tabLink.parentElement?.classList.add('menu__navigation-item--active');
                } else {
                    if (!isDesktop()) {
                        currentLinkArea.classList.add('menu__navigation--hide');
                        tabLink.classList.remove('menu__navigation-header--active');
                        tabLink.setAttribute('aria-expanded', 'false');
                        tabLink.parentElement?.classList.remove('menu__navigation-item--active');
                    }
                }

                if (!isDesktop()) {
                    if (list != null) {
                        list.style.height = calculateListHeight(tabLink) + 'px';
                    }
                    tabLink.scrollIntoView({ behavior: 'smooth' });
                }
            }
        });
    });

    if (isDesktop()) {
        sjekkOmBrukerErInnlogget();

        langButton != null ? langButton.classList.remove('menu__navigation--hide') : null;
        searchForm != null ? searchForm.classList.add('menu__navigation--hide') : null;
        //sprakNedtrekk != null ? sprakNedtrekk.classList.remove('menu__navigation--hide') : null;
        if (allLinkAreas != null && allLinkAreas.length > 0) allLinkAreas[0].classList.remove('menu__navigation--hide');

        if (allTabs != null && allTabs.length > 0) {
            allTabs[0].classList.add('menu__navigation-header--active');
            allTabs[0].setAttribute('aria-expanded', 'true');
            if (allTabs[0].parentElement != null) {
                allTabs[0].parentElement.classList.add('menu__navigation-item--active');
            }
        }

        highestListItemDesktop = getHighestItem();
    }

    window.addEventListener('resize', adjustToResizing);
    window.addEventListener('pageshow', () => {
        if (isDesktop()) {
            sjekkOmBrukerErInnlogget();
        }
    });
};
