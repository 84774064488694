const SELECTOR = '.js-slideIn';
const CHILDRENSELECTOR = '.js-slideIn--children';
const ANIMATE_CLASS_NAME = 'animate';

const alreadyFullyVisible = (element: Element): void => {
    element.classList.remove('js-slideIn');
    element.classList.remove('js-slideIn--children');
};

const animate = (element: Element): void => element.classList.add(ANIMATE_CLASS_NAME);

const isAnimated = (element: Element): boolean => element.classList.contains(ANIMATE_CLASS_NAME);

export const observeSlideInElements = (): IntersectionObserver => {
    const scrollElement = document.getElementById("scroll-element");
    if (!scrollElement) {
        throw Error('Fant ikke scroll-element')
    }

    const intersectionObserver = new IntersectionObserver(
        (entries: IntersectionObserverEntry[], observer: IntersectionObserver): void => {
            entries.forEach((entry: IntersectionObserverEntry): void => {
                const entryShouldBeAnimated = entry.intersectionRatio > 0.2 || entry.intersectionRect.height > 40;
                if (scrollElement.scrollTop === 0 && entryShouldBeAnimated) {
                    alreadyFullyVisible(entry.target);
                    observer.unobserve(entry.target);
                } else if (entryShouldBeAnimated) {
                    animate(entry.target);
                    observer.unobserve(entry.target);
                }
            });
        },
        {
            threshold: [0.05, 0.1, 0.15, 0.2],
        },
    );

    const elements = [].filter.call(
        document.querySelectorAll(`${SELECTOR}, ${CHILDRENSELECTOR}`),
        (element: Element): boolean => !isAnimated(element),
    );

    elements.forEach((element: Element): void => intersectionObserver.observe(element));

    return intersectionObserver;
};
