export
    const brukerInnlogget = () => new Promise<boolean>((resolve, reject) => {
        function getSiteType() {
            return document.body.getAttribute("data-site");
        }

        let siteType = getSiteType();
        // TODO: Hvorfor serialiseres denne som string og ikke number?
        if (siteType === "Lanekassen") { //LKNO
            fetch('/du/', {
                method: 'GET',
                credentials: 'include',
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                }
            })
                .then((response) => response.json())
                .then((json) => {
                    resolve((json as DuResponse).isAuthenticated);
                }).catch((err) => {
                    reject(err);
                });
        // TODO: Hvorfor serialiseres denne som string og ikke number?
        } else if (siteType === "Laresteder") { //LS
            // Inntil larestedsinfo.lanekassen.no flyttes til lanekassen.no/larestedsinfo så har ikke ls noe forhold til om brukeren er logget inn
            resolve(false);
        } else {
            resolve(false);
        }
    });

type DuResponse = { isAuthenticated: boolean, fornavn: string, etternavn: string, kortnavn: string }
